import { render, staticRenderFns } from "./TransportPage.vue?vue&type=template&id=6136f452&scoped=true&"
import script from "./TransportPage.vue?vue&type=script&lang=js&"
export * from "./TransportPage.vue?vue&type=script&lang=js&"
import style0 from "./TransportPage.vue?vue&type=style&index=0&id=6136f452&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6136f452",
  null
  
)

export default component.exports