<template>
  <div class="page page__content-wrapper transport">
    <div class="page__full-width transport-full">
      <div class="page__min-container top-block">
        <div class="text__head">
          <div class="top-border"></div>
          Газель
        </div>
        <div class="text__normal">
          <span class="blue">Боковая сторона:</span>
        </div>
        <div class="text__normal text-max-line">
          Самоклеющаяся пленка <br>
          Полноцветная печать, 720–1440 точек на дюйм <br>
          Ламинация
        </div>
      </div>
      <div class="page__link-block download-block">
        <a :href="gazel.url"
           @click.prevent="downloadItem(gazel)"
           class="btn__circle btn__nav-slider  btn-download">
          <img src="~assets/img/icons/icon-ai.svg" alt="AI">
        </a>
        <div class="description">
          Файл подготовлен для автомобиля GAZelle Next с 4-метровым кузовом.
        </div>
      </div>
    </div>
    <div class="page__full-width transport__block-info-row">
      <div class="page__min-container page__img-wrapper transport-1">
        <img src="~assets/img/transport-page/transport-1.png" alt="Баннер">
      </div>
    </div>
    <div class="page__full-width transport-full">
      <div class="page__min-container">
        <div class="text__head">
          <div class="top-border"></div>
          Грузовой автомобиль
        </div>
        <div class="page__min-container">
          <div class="text__normal">
            <span class="blue">Задние двери:</span>
          </div>
          <div class="text__normal text-bottom-margin">
            Самоклеющаяся пленка <br>
            Полноцветная печать,
            1440 точек на дюйм <br>
            Ламинация
          </div>
          <div class="text__normal">
            <span class="blue">Боковая сторона:</span>
          </div>
          <div class="text__normal text-max-line text-4">
            Самоклеющаяся пленка <br>
            Полноцветная печать,
            720–1440 точек на дюйм <br>
            Ламинация
          </div>
        </div>

      </div>
      <div class="page__link-block download-block">
        <a :href="truck.url"
           @click.prevent="downloadItem(truck)"
           class="btn__circle btn__nav-slider  btn-download">
          <img src="~assets/img/icons/icon-ai.svg" alt="AI">
        </a>
        <div class="description">
          Файл по умолчанию подготовлен для стандартного 14-метрового прицепа.
        </div>
      </div>
    </div>
    <div class="page__full-width transport__block-info-row">
      <div class="page__min-container page__img-wrapper" style="margin-bottom: 96px">
        <img src="~assets/img/transport-page/transport-2.png" alt="Баннер">
      </div>
    </div>
    <div class="page__download-block ">
      <a class="main-btn_download"
         :href="all.url"
         @click.prevent="downloadItem(all)">
        <svg style="margin-right: 8px;" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="24" cy="24" r="16" stroke="white" stroke-linecap="round" stroke-dasharray="0.1 4"/>
          <path
              d="M23.6464 26.3536C23.8417 26.5488 24.1583 26.5488 24.3536 26.3536L27.5355 23.1716C27.7308 22.9763 27.7308 22.6597 27.5355 22.4645C27.3403 22.2692 27.0237 22.2692 26.8284 22.4645L24 25.2929L21.1716 22.4645C20.9763 22.2692 20.6597 22.2692 20.4645 22.4645C20.2692 22.6597 20.2692 22.9763 20.4645 23.1716L23.6464 26.3536ZM24.5 18L24.5 17.5L23.5 17.5L23.5 18L24.5 18ZM24.5 26L24.5 18L23.5 18L23.5 26L24.5 26Z"
              fill="white"/>
          <path d="M31 27V29C31 29.5523 30.5523 30 30 30H18C17.4477 30 17 29.5523 17 29V27" stroke="white"
                stroke-linecap="square"/>
        </svg>
        Скачать все макеты
      </a>
    </div>

    <div class="page-nav__tablet">
      <router-link class="next-page__link" to="/brand/event-decoration">
        <img src="~assets/img/icons/row-grey-small.svg" alt="prev">
        Оформление мероприятий
      </router-link>
      <router-link class="next-page__link" to="/brand/smm">
        SMM
        <img src="~assets/img/icons/row-grey-small.svg" alt="next">
      </router-link>
    </div>
    <div class="next-page">
      <router-link class="next-page__link" to="/brand/smm">
        SMM
        <img src="~assets/img/icons/row-grey-small.svg" alt="next">
      </router-link>
    </div>
  </div>
</template>
<script>
import Axios from "axios";

export default {
  data() {
    return {
      gazel: {
        url: '../dowload-file/Brand--Office/transport/GDP-Gazel.zip',
        label: "GDP-Gazel"
      },
      truck: {
        url: '../dowload-file/Brand--Office/transport/GDP-Truck.zip',
        label: "GDP-Truck"
      },
      all: {
        url: '../dowload-file/Brand--Office/transport/Transport.zip',
        label: "Transport"
      }
    }
  },
  methods: {
    downloadItem({url, label}) {
      Axios.get(url, {responseType: 'blob'})
          .then(response => {
            const blob = new Blob([response.data], {type: 'application/zip'})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = label
            link.click()
            URL.revokeObjectURL(link.href)
          }).catch(console.error)
    },
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/style/page-block";
@import "src/assets/style/text-style";
@import "src/assets/style/buttons";

.page {
  margin-bottom: 192px;

  .page__full-width {
    .page__link-block {
      margin-left: 20px;
      .description {
        width: 200px;
        margin-top: 32px;
        margin-left: 12px;
        font-family: Formular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #8698A0;
      }
    }

    .page__min-container {
      margin-bottom: 32px;

      .text__head {
        margin-bottom: 9px;
      }

      .text__normal {
        margin-bottom: 12px;
        letter-spacing: 0.19px;
      }
    }

    &:nth-child(2) {
      .page__min-container {
        margin-bottom: 96px;
      }
    }

    &:nth-child(3) {
      .page__min-container {
        .text__normal {
          margin-bottom: 22px;
        }
      }
    }

  }

  .page__min-container {
    margin-bottom: 95px;
  }

  .main-btn_download {
    letter-spacing: 0.85px;
    font-weight: 700;
    font-family: 'formular', serif;
  }
}

.blue {
  color: #000033;
}

.transport__block-info-row {
  display: flex;
}

.transport-full {
  display: flex;
}

.text-max-line {
  line-height: 24px;
}

.text__normal {
  font-weight: 100;
  color: #606080;

  b {
    font-weight: 500;
    color: #000033;
  }
}

.text__head {
  font-weight: 500;
  margin-bottom: 12px;
}

.text__normal {
  margin-bottom: 21px;
}

.top-block {
  margin-bottom: 26px;
}

.transport-1 {
  margin-bottom: 38px;
}

.text-2 {
  letter-spacing: 0.2px;
  margin-bottom: 45px;
}

.text-3 {
  letter-spacing: 0.2px;
  margin-bottom: 61px;
}

.text-4 {
  letter-spacing: 0.2px;
  margin-bottom: -20px;
}

.text-5 {
  letter-spacing: 0.2px;
  margin-top: -20px;
}

.text-6 {
  letter-spacing: 0.2px;
  margin-top: 57px;
}

.text-bottom-margin {
  margin-bottom: 30px;
}

.dark-text {
  color: #000033;
}

.text__normal {
  font-weight: 400;
  font-size: 16px;

  b {
    color: #000033;
    font-weight: 400;
  }
}

@media screen and (max-width: 1456px) {
  .page {
    margin-bottom: 208px;
  }
}

@media screen and (max-width: 1246px) {
  .page__download-block {
    display: none;
  }
}

@media screen and (max-width: 1216px) {
  .pages-wrap {
    .container {
      .page {
        .page__full-width {
          &.transport-full {
            width: 100%;
          }
        }

        .page__download-block {
          display: none;
        }
      }
    }
  }

}

</style>
